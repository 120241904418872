import { Dispatch } from 'redux';
import Axios from 'axios';
import {
  getConfigVar,
  AccountDetailsAPIResponse,
  CreateAccountAPIResponse,
  Account,
  formatAPIError,
  Patient,
  Pharmacy,
  PatientIntegrationSettings,
  PatientIntegration,
  Group,
  AccountStatusAPIResponse,
  platforms,
  RegisterPFSUserAPIResponse,
  CreateB2CUserAPIResponse,
  SignInAPIResponse,
  UpdatePatientAPIResponse,
  CreateAccountNHSAPIResponse,
  HANDLED_ERROR_CODES,
  IM1AccountDetailsAPIResponse,
  NotificationPreferenceItem,
  UpdateNotificationPreferencesAPIResponse,
  Response,
  NHSLoginAPIResponse,
} from '@avicennapharmacy/managemymeds-shared';
import { trackEvent } from 'utils/applicationInsights';
import { clearTokens, storeTokens } from 'utils/tokenStorage';
import { authorizeNHSLogin } from 'utils/login';
import Routes from 'routes';
import addAxiosAuthInterceptors from 'utils/addAxiosAuthInterceptors';
import { regVersions, regSections } from 'constants/registration';
import { resetAll } from 'redux/reducers';
import { showLoginErrorMessage, getErrorMessage } from 'components/ErrorHandler';
import { setAlert } from 'redux/actions/alert';
import { toast } from 'react-toastify';
import { alertFunctions } from '../alert';

export const userActionTypes = {
  FETCH: 'USER_FETCH',
  FETCHED: 'USER_FETCHED',
  REFETCHED: 'USER_REFETCHED',
  RESET_REFETCHED: 'USER_RESET_REFETCHED',
  UPDATE_ACCOUNT_DETAILS: 'USER_UPDATE_ACCOUNT_DETAILS',
  UPDATE_PATIENT_DETAILS: 'USER_UPDATE_PATIENT_DETAILS',
  UPDATE_PHARMACY_DETAILS: 'USER_UPDATE_PHARMACY_DETAILS',
  UPDATE_PATIENT_INTEGRATION_DETAILS: 'USER_UPDATE_PATIENT_INTEGRATION_DETAILS',
  UPDATE_PATIENT_INTEGRATION: 'USER_UPDATE_PATIENT_INTEGRATION',
  UPDATE_GROUP: 'UPDATE_GROUP',
  EMAIL_UNIQUENESS_CHECKING: 'EMAIL_UNIQUENESS_CHECKING',
  EMAIL_UNIQUENESS_CHECK: 'EMAIL_UNIQUENESS_CHECK',
  USER_ERROR: 'USER_ERROR',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  UPDATE_EMAIL_VERIFICATION_STATUS: 'UPDATE_EMAIL_VERIFICATION_STATUS',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  EMAIL_VERIFICATION_ERROR: 'EMAIL_VERIFICATION_ERROR',
  ACCOUNT_STATUS_CHECK: 'ACCOUNT_STATUS_CHECK',
  MANUAL_LINKING_COMPLETED: 'MANUAL_LINKING_COMPLETED',
  CREATE_B2CUSER: 'CREATE_B2CUSER',
  SIGN_IN_COMPLETED: 'SIGN_IN_COMPLETED',
  RESET_TEMPORARY_STATES: 'RESET_TEMPORARY_STATES',
  CREATE_PATIENT: 'CREATE_PATIENT',
  NHS_LINKING_PROCESSING: 'NHS_LINKING_PROCESSING',
  NHS_AUTHORIZATION_COMPLETED: 'NHS_AUTHORIZATION_COMPLETED',
  NHS_LINKING_COMPLETED: 'NHS_LINKING_COMPLETED',
  USER_CANCELLED_FLOW: 'USER_CANCELLED_FLOW',
  SIGNIN_PROCESSING: 'SIGNIN_PROCESSING',
  USER_PROCESS_LOADING: 'USER_PROCESS_LOADING',
  UPDATE_PATIENT_PHARMACY: 'UPDATE_PATIENT_PHARMACY',
  UPDATE_PATIENT: 'UPDATE_PATIENT',
  UPDATING_PATIENT: 'UPDATING_PATIENT',
  LOGIN_ERROR: 'LOGIN_ERROR',
  IM1_CONNECTION_PROCESS_STARTED: 'IM1_CONNECTION_PROCESS_STARTED',
  IM1_CONNECTION_PROCESS_COMPLETED: 'IM1_CONNECTION_PROCESS_COMPLETED',
  IM1_CONNECTION_PROCESS_ERROR: 'IM1_CONNECTION_PROCESS_ERROR',
  CHANGE_MARKETING_OPTION: 'CHANGE_MARKETING_OPTION',
  RESET_USER_ERROR: 'RESET_USER_ERROR',
  CREATE_PATIENT_LOADING: 'CREATE_PATIENT_LOADING',
};
export const USER_CANCELLED_FLOW = 'User cancelled flow';

export const fetchUserDetails = (refetch?: boolean) => async (dispatch: Dispatch) => {
  dispatch({ type: userActionTypes.FETCH });
  try {
    const { data } = await Axios.get<AccountDetailsAPIResponse>(getConfigVar('accountDetailsEndpoint'));
    if (data?.patient?.iM1Available === true) {
      const im1Accountdetails = await Axios.post<IM1AccountDetailsAPIResponse>(
        getConfigVar('im1AccountDetailsEndpoint'),
      );
      dispatch({
        type: userActionTypes.FETCHED,
        payload: {
          ...data,
          integrationSettings: im1Accountdetails?.data.integrationSettings,
          linkedPatients: im1Accountdetails?.data.linkedPatients,
        },
      });
    } else {
      dispatch({ type: userActionTypes.FETCHED, payload: data });
    }
  } catch (e) {
    await clearTokens();
    dispatch(alertFunctions.showErrorAlert('Unable to retrieve account details at this time. You have been forced to log out') as any);
    setTimeout(() => {
      window.location.assign(Routes.LOGIN);
    }, 5000);
    trackEvent('AccountDetailsForceLogout', { route: 'Logout' });
  } finally {
    if (refetch) {
      dispatch({ type: userActionTypes.REFETCHED });
    }
  }
};

export const resetRefetched = () => async (dispatch: Dispatch) => {
  dispatch({ type: userActionTypes.RESET_REFETCHED });
};

export const updateAccountDetails = (account: Account) => async (dispatch: Dispatch) => {
  dispatch({ type: userActionTypes.UPDATE_ACCOUNT_DETAILS, payload: account });
};

export const updatePatientDetails = (patient: Patient) => async (dispatch: Dispatch) => {
  dispatch({ type: userActionTypes.UPDATE_PATIENT_DETAILS, payload: patient });
};

export const updatePharmacyDetails = (pharmacy: Pharmacy) => async (dispatch: Dispatch) => {
  dispatch({ type: userActionTypes.UPDATE_PHARMACY_DETAILS, payload: pharmacy });
};

export const updateGroupDetails = (group: Group | null) => async (dispatch: Dispatch) => {
  dispatch({ type: userActionTypes.UPDATE_GROUP, payload: group });
};

export const updatePatientIntegrationDetails = (
  patientIntegration: PatientIntegrationSettings | null,
) => async (dispatch: Dispatch) => {
  dispatch({
    type: userActionTypes.UPDATE_PATIENT_INTEGRATION_DETAILS,
    payload: patientIntegration,
  });
};

export const updatePatientIntegration = (patientIntegration: PatientIntegration | any) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: userActionTypes.UPDATE_PATIENT_INTEGRATION, payload: patientIntegration });
};

export type handleRegisterProps = {
  dob: string;
  surname: string;
  linkageKey: string;
  ODSCode: string;
  accountId: string;
  secondaryPatient: boolean;
};

export const handleRegister = (
  {
    dob,
    surname,
    linkageKey,
    ODSCode,
    accountId,
    secondaryPatient,
  }: handleRegisterProps,
  navigateTo: Function,
) => async (dispatch: Dispatch) => {
  try {
    await Axios.post(getConfigVar('registerUserEndpoint'), {
      surname,
      dateOfBirth: dob,
      linkageDetails: {
        accountId,
        linkageKey,
        nationalPracticeCode: ODSCode,
      },
      deviceType: 'web',
      secondaryPatient,
    });
    trackEvent('RegisterForOnlineServices');
    updatePatientIntegration({ nationalPracticeCode: ODSCode });

    const {
      data: {
        integrationSettings, patient, account, pharmacy,
      },
    } = await Axios.get<AccountDetailsAPIResponse>(getConfigVar('accountDetailsEndpoint'));

    if (integrationSettings) {
      updatePatientIntegrationDetails(integrationSettings);
    }

    if (patient) {
      updatePatientDetails(patient);
    }

    if (account) {
      updateAccountDetails(account);
    }

    if (pharmacy) {
      updatePharmacyDetails(pharmacy);
    }

    navigateTo();
  } catch (error) {
    trackEvent('RegisterForOnlineServicesError', { error });
    const { message } = formatAPIError(error?.response,
      'Unable to register for online services at this time. Please try again.');
    dispatch(alertFunctions.showErrorAlert(message) as any);
  }
};

export const changeGP = (
  {
    dob,
    surname,
    linkageKey,
    ODSCode,
    accountId,
    secondaryPatient,
  }: handleRegisterProps,
  navigateTo: Function,
) => async (dispatch: Dispatch) => {
  try {
    await Axios.post(getConfigVar('reRegisterPFSUserEndpoint'), {
      surname,
      dateOfBirth: dob,
      linkageDetails: {
        accountId,
        linkageKey,
        nationalPracticeCode: ODSCode,
      },
      deviceType: 'web',
      secondaryPatient,
    });
    trackEvent('ChangeGP');
    updatePatientIntegration({ nationalPracticeCode: ODSCode });

    const {
      data: {
        integrationSettings, patient, account, pharmacy,
      },
    } = await Axios.get<AccountDetailsAPIResponse>(getConfigVar('accountDetailsEndpoint'));

    if (integrationSettings) {
      updatePatientIntegrationDetails(integrationSettings);
    }

    if (patient) {
      updatePatientDetails(patient);
    }

    if (account) {
      updateAccountDetails(account);
    }

    if (pharmacy) {
      updatePharmacyDetails(pharmacy);
    }

    navigateTo();
  } catch (error) {
    trackEvent('ChangeGPError', { error });
    const { message } = formatAPIError(error?.response,
      'Unable to register for online services at this time. Please try again.');
    dispatch(alertFunctions.showErrorAlert(message) as any);
  }
};

export const resetEmailUniquenessCheck = () => async (dispatch: Function) => {
  dispatch({
    type: userActionTypes.EMAIL_UNIQUENESS_CHECKING,
    payload: {
      checking: false,
      checked: false,
    },
  });
};

export const emailUniquenessCheck = (email: string) => async (dispatch: Function) => {
  dispatch({
    type: userActionTypes.EMAIL_UNIQUENESS_CHECKING,
    payload: {
      checking: true,
      checked: false,
    },
  });

  try {
    const { data } = (
      await Axios.post<AccountStatusAPIResponse>(`${getConfigVar('getAccountStatusEndpoint')}?action=uniquecheck`, {
        emailAddress: email,
      })
    );
    dispatch({
      type: userActionTypes.EMAIL_UNIQUENESS_CHECK,
      payload: {
        id: data.accountId,
        accountStatus: data.accountStatus ? data.accountStatus : true,
        b2CObjectId: data.b2CObjectId,
      },
    });
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('EmailUniquenessCheckError', { error: e });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );
  }
};

export const createAccount = (email: string, marketingOption: boolean) => async (dispatch: Function) => {
  try {
    const { data } = await Axios.post<CreateAccountAPIResponse>(getConfigVar('createAccountEndpoint'), {
      email,
      platform: platforms.WEB,
      marketingOptin: marketingOption,
    });
    trackEvent('SendVerificationEmail');

    dispatch({
      payload: data.account,
      type: userActionTypes.CREATE_ACCOUNT,
    });
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('CreateAccountError', {
      Email: email,
      error: e,
    });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );
  }
};

export const verifyEmail = (token: string) => async (dispatch: Function) => {
  dispatch({
    type: userActionTypes.UPDATE_EMAIL_VERIFICATION_STATUS,
    payload: true,
  });

  try {
    const { data } = await Axios.get<Account>(`${getConfigVar('verifyEmailEndpoint')}/${token}`);

    if (data?.id) {
      dispatch({
        type: userActionTypes.EMAIL_VERIFICATION,
        payload: data,
      });
    } else {
      dispatch({
        type: userActionTypes.EMAIL_VERIFICATION_ERROR,
      });
    }
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('VerifyEmailError', { error: e });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );

    dispatch({
      type: userActionTypes.EMAIL_VERIFICATION_ERROR,
    });
  }
};

export const accountStatusCheck = (email: string) => async (dispatch: Function) => {
  try {
    const { data } = await Axios.post<AccountStatusAPIResponse>(
      `${getConfigVar('getAccountStatusEndpoint')}?action=verify`,
      {
        emailAddress: email,
      },
    );

    if (data?.accountStatus) {
      dispatch({
        payload: data,
        type: userActionTypes.ACCOUNT_STATUS_CHECK,
      });
    }
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('AccountStatusCheckError', {
      Email: email,
      error: e,
    });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );
  }
};

export const setLoadingStatus = (currentStatus: boolean) => (dispatch: Function) => {
  dispatch({
    type: userActionTypes.USER_PROCESS_LOADING,
    payload: currentStatus,
  });
};

export const completeManualLinking = (
  accountId: string,
  surname: string,
  date: Date,
  onlineAccountId: string,
  linkageKey: string,
  odsCode: string,
) => async (dispatch: Function) => {
  try {
    const { data } = await Axios.put<RegisterPFSUserAPIResponse>(getConfigVar('registerUserEndpoint'), {
      accountId,
      surname,
      dateOfBirth: date,
      linkageDetails: {
        accountId: onlineAccountId,
        linkageKey,
        nationalPracticeCode: odsCode,
      },
      deviceType: platforms.WEB,
    });

    if (data?.isSuccess && data?.requiresLogin) {
      dispatch({
        payload: data,
        type: userActionTypes.MANUAL_LINKING_COMPLETED,
      });
    } else {
      dispatch({
        payload: { isSuccess: true, requiresLogin: false },
        type: userActionTypes.MANUAL_LINKING_COMPLETED,
      });
    }
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('CompleteManualLinkingError', { error: e });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );

    dispatch(setLoadingStatus(false));
  }
};

export const createB2CUser = (accountId: string, email: string, password: string) => async (dispatch: Function) => {
  try {
    if (!accountId || !email || !password) {
      trackEvent('CreateB2CUserError');

      dispatch({
        payload: {
          message: 'The account could not be created. Please try again later.',
        },
        type: userActionTypes.USER_ERROR,
      });
    }

    dispatch({
      payload: {
        message: 'The account could not be created. Please try again later.',
      },
      type: userActionTypes.USER_PROCESS_LOADING,
    });

    const b2cUser = (
      await Axios.post<CreateB2CUserAPIResponse>(getConfigVar('createB2CUserEndpoint'), {
        accountId,
        firstName: 'FIRST_NAME',
        lastName: 'LAST_NAME',
        password,
        email,
      })
    ).data;

    dispatch({
      payload: b2cUser,
      type: userActionTypes.CREATE_B2CUSER,
    });
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('CreateB2CUserError', {
      Email: email,
      error: e,
    });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );

    dispatch(setLoadingStatus(false));
  }
};

export const signIn = (email: string, password: string) => async (dispatch: Function) => {
  try {
    const token = (
      await Axios.post<SignInAPIResponse>(getConfigVar('signInEndpoint'), {
        username: email,
        password,
        platform: platforms.WEB,
      })
    ).data;

    if (token.access_token) {
      storeTokens(token.access_token, token.refresh_token);
      await addAxiosAuthInterceptors();
    } else {
      trackEvent('SignInError', {
        username: email,
      });
      throw Error();
    }
    dispatch({
      payload: true,
      type: userActionTypes.SIGN_IN_COMPLETED,
    });
  } catch (e) {
    trackEvent('SignInError', {
      Email: email,
      error: e,
    });

    dispatch({
      payload: {
        message: 'Unexpected error happened. Please try again later.',
      },
      type: userActionTypes.USER_ERROR,
    });
  }
};

export const createPatient = (patient: Patient) => async (dispatch: Function) => {
  dispatch({
    type: userActionTypes.CREATE_PATIENT_LOADING,
    payload: true,
  });

  try {
    const { data } = (
      await Axios.put<UpdatePatientAPIResponse>(getConfigVar('createPatientEndpoint'), {
        consentSigned: patient?.consentSigned,
        hasConsent: patient?.consentSigned,
        accountId: patient.accountId,
        isRegCall: true,
        regVersion: regVersions.V4Manual,
        regSection: regSections.MANUAL_IDENTITY,
        lastRegSectionId: 'CreatePassword',
        regPlatform: platforms.WEB,
        history: [],
        firstName: patient.firstName,
        lastName: patient.lastName,
        primaryContactNumber: patient.primaryContactNumber,
        dateOfBirth: patient?.dateOfBirth,
      })
    );

    dispatch({
      type: userActionTypes.CREATE_PATIENT,
      payload: data,
    });

    dispatch({
      type: userActionTypes.CREATE_PATIENT_LOADING,
      payload: false,
    });
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('CreatePatientError', { error: e });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );

    dispatch({
      type: userActionTypes.CREATE_PATIENT_LOADING,
      payload: false,
    });
  }
};

export const resetTemporaryStates = () => async (dispatch: Function) => {
  dispatch({ type: userActionTypes.RESET_TEMPORARY_STATES });
};

export type RegisterWithNHSOptions = {
  isNHSLogin: boolean;
  marketingOptin?: boolean;
  hasConsent?: boolean;
  email?: string;
  accountId?: string;
  history?: string[];
  redirectUri: string;
  authorisationCode?: string;
};

export const authorizeNHSAccount = (redirectUri: string) => async (dispatch: Function) => {
  dispatch({
    payload: true,
    type: userActionTypes.NHS_LINKING_PROCESSING,
  });

  await authorizeNHSLogin(redirectUri);

  dispatch({
    payload: true,
    type: userActionTypes.NHS_AUTHORIZATION_COMPLETED,
  });
};

export const updateNHSProcessStatus = (status: boolean) => async (dispatch: Function) => {
  dispatch({ type: userActionTypes.NHS_LINKING_PROCESSING, payload: status });
};

export const registerWithNHS = (props: RegisterWithNHSOptions) => async (dispatch: Function) => {
  dispatch({ type: userActionTypes.NHS_LINKING_PROCESSING, payload: true });
  try {
    let userAccountId = props.accountId;

    if (!props.isNHSLogin) {
      const { data } = await Axios.post<CreateAccountAPIResponse>(getConfigVar('createAccountEndpoint'), {
        email: props.email,
        platform: platforms.WEB,
        marketingOptin: false,
        isNHSLoginRegister: true,
      });
      userAccountId = data.account.id;
    }

    const options: RegisterWithNHSOptions = {
      ...props,
      marketingOptin: false,
      hasConsent: true,
      accountId: userAccountId,
    };

    let response;

    try {
      response = (
        await Axios.post<CreateAccountNHSAPIResponse>(getConfigVar('createaccountnhsEndpoint'), {
          platform: platforms.WEB,
          deviceType: 'web',
          ...options,
        })
      ).data;
    } catch (e) {
      if (HANDLED_ERROR_CODES.includes(e?.response?.status)) {
        throw Error(e?.response?.data?.message);
      }
      throw e;
    }
    await storeTokens(response.access_token, response.refresh_token);
    await addAxiosAuthInterceptors();

    dispatch({
      type: userActionTypes.NHS_LINKING_COMPLETED,
      payload: response,
    });
  } catch (e) {
    trackEvent('CreateAccountWithNHSError', { error: e });

    dispatch({
      payload: false,
      type: userActionTypes.NHS_LINKING_PROCESSING,
    });

    if (e.message !== USER_CANCELLED_FLOW) {
      const message = await getErrorMessage(e?.response?.data);

      dispatch(
        setAlert({
          message,
          alertType: toast.TYPE.ERROR,
          autoClose: 10000,
          position: toast.POSITION.TOP_CENTER,
          navigationObject: null,
        }),
      );
    }
  }
};

export const signInWithNHS = (props: RegisterWithNHSOptions) => async (dispatch: Function) => {
  dispatch({
    payload: true,
    type: userActionTypes.NHS_LINKING_PROCESSING,
  });

  try {
    dispatch({
      payload: true,
      type: userActionTypes.NHS_AUTHORIZATION_COMPLETED,
    });

    const response = (
      await Axios.post<Response>(getConfigVar('nhsLoginEndpoint'), {
        platform: platforms.WEB,
        deviceType: 'web',
        ...props,
      })
    ).data;

    const nhsLoginResponse: NHSLoginAPIResponse = response?.content as NHSLoginAPIResponse;

    await storeTokens(nhsLoginResponse.access_token, nhsLoginResponse.refresh_token);
    await addAxiosAuthInterceptors();

    dispatch({
      type: userActionTypes.NHS_LINKING_COMPLETED,
      payload: response,
    });
  } catch (e) {
    trackEvent('SignInWithNHSError', { error: e });

    dispatch({
      payload: false,
      type: userActionTypes.NHS_LINKING_PROCESSING,
    });

    if (e.message !== USER_CANCELLED_FLOW) {
      const message = await getErrorMessage(e?.response?.data);

      dispatch(
        setAlert({
          message,
          alertType: toast.TYPE.ERROR,
          autoClose: 10000,
          position: toast.POSITION.TOP_CENTER,
          navigationObject: null,
        }),
      );
    }
  }
};

export const loginWithEmailAndPassword = (email: string, password: string) => async (dispatch: Function) => {
  dispatch({
    type: userActionTypes.SIGNIN_PROCESSING,
    payload: true,
  });

  dispatch({
    payload: null,
    type: userActionTypes.USER_ERROR,
  });

  try {
    const loginResponse = await Axios.post<SignInAPIResponse>(getConfigVar('signInEndpoint'), {
      username: email,
      password,
      platform: platforms.WEB,
    });
    const accessToken = loginResponse.data.access_token;
    const refreshToken = loginResponse.data.refresh_token;

    if (!accessToken || !refreshToken) {
      throw Error();
    }

    trackEvent('LoginWithEmailAndPassword');
    dispatch(resetAll(true));
    await storeTokens(accessToken, refreshToken);
    await addAxiosAuthInterceptors();

    trackEvent('GetAccountDetails');
    dispatch(fetchUserDetails());
    dispatch({
      payload: true,
      type: userActionTypes.SIGN_IN_COMPLETED,
    });
  } catch (e) {
    trackEvent('LoginWithEmailAndPasswordError', { error: e });

    dispatch(showLoginErrorMessage(email));

    dispatch({
      type: userActionTypes.USER_PROCESS_LOADING,
      payload: false,
    });
  }

  dispatch({
    type: userActionTypes.SIGNIN_PROCESSING,
    payload: false,
  });
};

export const setLoginError = (error: string) => async (dispatch: Function) => {
  dispatch({ type: userActionTypes.LOGIN_ERROR, payload: error });
};

export const updatePatient = (patient: Patient, regVersion?: string, isPharmacyOnly?: boolean) => async (
  dispatch: Function,
) => {
  try {
    dispatch({
      type: userActionTypes.UPDATING_PATIENT,
    });
    const { data } = (
      await Axios.put<UpdatePatientAPIResponse>(getConfigVar('updatePatientEndpoint'), {
        onlyPharmacyUpdate: isPharmacyOnly ?? false,
        regVersion: regVersion ?? regVersions.V4Manual,
        ...patient,
      })
    );

    dispatch({
      type: userActionTypes.UPDATE_PATIENT,
      payload: data,
    });

    trackEvent('GetAccountDetails');
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('UpdatePatientError', { error: e });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );

    dispatch({
      type: userActionTypes.USER_PROCESS_LOADING,
      payload: false,
    });
  }
};

export const updatePatientPharmacy = (
  patient: Patient,
  selectedPharmacyId: string,
  regVersion: string,
  regSection: string,
) => async (dispatch: Function) => {
  dispatch({
    type: userActionTypes.USER_PROCESS_LOADING,
    payload: true,
  });

  try {
    const { data } = await Axios.put<Patient>(getConfigVar('updatePatientEndpoint'), {
      ...patient,
      pharmacyId: selectedPharmacyId,
      regVersion,
      regSection,
    } as Patient);

    dispatch({
      type: userActionTypes.UPDATE_PATIENT_PHARMACY,
      payload: data,
    });
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('UpdatePatientPharmacyError', { error: e });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );

    dispatch({
      type: userActionTypes.USER_PROCESS_LOADING,
      payload: false,
    });
  }

  dispatch({
    type: userActionTypes.USER_PROCESS_LOADING,
    payload: false,
  });
};

export const connectIM1viaLinkageLetter = (
  accountId: string,
  surname: string,
  date: Date,
  onlineAccountId: string,
  linkageKey: string,
  odsCode: string,
) => async (dispatch: Function) => {
  dispatch({ type: userActionTypes.IM1_CONNECTION_PROCESS_STARTED });

  try {
    const { data } = await Axios.post<RegisterPFSUserAPIResponse>(getConfigVar('connectIM1LinkageLetterEndpoint'), {
      accountId,
      surname,
      dateOfBirth: date,
      linkageDetails: {
        accountId: onlineAccountId,
        linkageKey,
        nationalPracticeCode: odsCode,
      },
      deviceType: platforms.WEB,
    });

    if (data?.isSuccess && data?.requiresLogin) {
      dispatch({
        payload: data,
        type: userActionTypes.IM1_CONNECTION_PROCESS_COMPLETED,
      });
    } else {
      dispatch({
        payload: { isSuccess: true, requiresLogin: false },
        type: userActionTypes.IM1_CONNECTION_PROCESS_COMPLETED,
      });
    }
  } catch (e) {
    const message = await getErrorMessage(e?.response?.data);

    trackEvent('ConnectIM1viaLikageLetterError', { error: e });

    dispatch(
      setAlert({
        message,
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      }),
    );

    dispatch({
      payload: {
        message: e?.response?.data.message,
      },
      type: userActionTypes.IM1_CONNECTION_PROCESS_ERROR,
    });

    dispatch({
      type: userActionTypes.USER_PROCESS_LOADING,
      payload: false,
    });
  }
};

export type ConnectIM1ViaNHSLoginProps = {
  marketingOptin?: boolean;
  hasConsent?: boolean;
  email?: string;
  accountId?: string;
  history?: string[];
  redirectUri: string;
  authorisationCode?: string;
};

export const connectIM1ViaNHSLogin = (props: ConnectIM1ViaNHSLoginProps) => async (dispatch: Function) => {
  dispatch({ type: userActionTypes.IM1_CONNECTION_PROCESS_STARTED });

  try {
    const options: ConnectIM1ViaNHSLoginProps = {
      ...props,
      marketingOptin: false,
      hasConsent: false,
      history: [],
    };

    const { data } = await Axios.post<RegisterPFSUserAPIResponse>(getConfigVar('connectIM1NHSLoginEndpoint'), {
      platform: platforms.WEB,
      deviceType: 'web',
      ...options,
    });

    if (data?.isSuccess && data?.requiresLogin) {
      dispatch({
        payload: data,
        type: userActionTypes.IM1_CONNECTION_PROCESS_COMPLETED,
      });
    } else {
      dispatch({
        payload: { isSuccess: true, requiresLogin: false },
        type: userActionTypes.IM1_CONNECTION_PROCESS_COMPLETED,
      });
    }
  } catch (e) {
    trackEvent('ConnectIM1viaNHSLoginError', { error: e });
    if (e.message !== USER_CANCELLED_FLOW) {
      const message = await getErrorMessage(e?.response?.data);

      dispatch(
        setAlert({
          message,
          alertType: toast.TYPE.ERROR,
          autoClose: 10000,
          position: toast.POSITION.TOP_CENTER,
          navigationObject: null,
        }),
      );
    }

    dispatch({
      payload: {
        message: e?.response?.data.message,
      },
      type: userActionTypes.IM1_CONNECTION_PROCESS_ERROR,
    });

    dispatch({
      type: userActionTypes.USER_PROCESS_LOADING,
      payload: false,
    });
  }
};

export const updateMarketingOption = (option: boolean) => async (dispatch: Function) => {
  dispatch({
    type: userActionTypes.CHANGE_MARKETING_OPTION,
    payload: option,
  });
};

export const updateNotificationPreferences = ({
  account,
  onError,
  items,
}: {
  account: Account;
  onError: (error: any) => void;
  items: NotificationPreferenceItem[];
}) => async (dispatch: Function) => {
  dispatch(setLoadingStatus(true));

  try {
    const { data } = await Axios.put<UpdateNotificationPreferencesAPIResponse>(
      getConfigVar('updateNotificationPreferencesEndpoint'),
      { items },
    );

    trackEvent('UpdateNotificationPreferences', { items: JSON.stringify(items) });
    dispatch(
      updateAccountDetails({
        ...account,
        notificationPreferences: data,
      }),
    );

    dispatch(setLoadingStatus(false));
  } catch (e) {
    dispatch(setLoadingStatus(false));

    trackEvent('UpdateNotificationPreferencesError', { error: e });
    onError(e);
  }
};

export const resetUserError = () => (dispatch: Dispatch) => {
  dispatch({
    type: userActionTypes.RESET_USER_ERROR,
  });
};

export const checkPharmacyStatus = (
  isPharmacyLive: boolean,
  history: any,
  autoNavigate: boolean,
  isServiceMessage: boolean,
) => async (dispatch: Function) => {
  if (!isPharmacyLive) {
    dispatch(
      setAlert({
        title: 'Oops!',
        message: `Your selected pharmacy is no longer using Manage My Meds, 
            therefore, you will not be able to ${isServiceMessage ? 'book a service' : 'place your repeat medication order'} 
            via your Manage My Meds account. ${autoNavigate ? 'Please change your pharmacy' : 'Please click here to change pharmacy'}`,
        alertType: toast.TYPE.WARNING,
        autoClose: false,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: { pathname: Routes.CHANGE_PHARMACY, state: { isChangePharmacy: true } },
      }),
    );
    if (autoNavigate) {
      history.push({ pathname: Routes.CHANGE_PHARMACY, state: { isChangePharmacy: true } });
    }
  }
};
