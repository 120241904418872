import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import { GlobalState } from 'redux/reducers';
import MultiPatientSwitcher from 'components/MultiPatientSwitcher';
import OrdersCard from 'components/Cards/OrdersCard';
import CardsContainer from 'components/Containers/CardsContainer';
import MessagesCard from 'components/Cards/Messages';
import ScheduleCard from 'components/Cards/ScheduleCard';
import Space from 'components/Space';
import {
  isIM1Enabled,
  isLiveOrder,
  Order,
  getConfigVar,
  Service,
  Pharmacy,
  COVID19_VAC_AND_BOOSTER_SERVICE,
  Patient,
  ServiceNamesLookup,
} from '@avicennapharmacy/managemymeds-shared';
import * as orderActions from 'redux/actions/orders';
import AnnouncementCard from 'components/Cards/AnnouncementCard';
import { selectMessagesEnabled, selectPharmacyServiceBookingEnabled } from 'redux/selectors';
import BookPharmacyServiceCard from 'components/Cards/BookPharmacyServiceCard';
import AllergyTestingCard from 'components/Cards/AllergyTestingCard';
import PromotedService from 'components/Cards/PromotedService';
import RoundedBox from 'components/RoundedBox';
import DownloadAppStore from 'assets/downloadAppStore.svg';
import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';
import Routes from 'routes';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import * as userActions from 'redux/actions/user';
import PromotionCard from 'components/Cards/PromotionCard';
import IM1Reminder from 'components/IM1Reminder';
import OnlineDoctorCard from 'components/Cards/OnlineDoctorCard';
import AviClinicCard from 'components/Cards/AviClinicCard';

const TextCenteredRoundedBox = styled(RoundedBox)`
  text-align: center;
`;

type HomeProps = {
  setSelectedService: (service: Service | null) => void;
  setSelectedSubService: (subService: string | null) => void;
  fetchOrderDetails: () => void;
  fetchServices: (pharmacyId: string) => void;
  checkPharmacyStatus: (
    isPharmacyLive: boolean,
    history: any,
    autoNavigate: boolean,
    isServiceMessage: boolean,
  ) => void;
  loading: boolean;
  isIM1Registered: boolean;
  allowManualReordering: boolean;
  serviceBookingEnabled: boolean;
  messagesEnabled?: boolean;
  orders: Order[];
  orderDetailsLoading: boolean;
  orderDetailsFetched: boolean;
  showIOSBanner: boolean;
  pharmacy: Pharmacy | null;
  patient: Patient;
  serviceNamesLookup: ServiceNamesLookup | null;
  services: Service[];
};

const Home = ({
  setSelectedService,
  setSelectedSubService,
  fetchOrderDetails,
  fetchServices,
  checkPharmacyStatus,
  loading,
  isIM1Registered,
  allowManualReordering,
  serviceBookingEnabled,
  messagesEnabled,
  orders,
  orderDetailsLoading,
  orderDetailsFetched,
  showIOSBanner,
  pharmacy,
  patient,
  history,
  serviceNamesLookup,
  services,
}: HomeProps & RouteChildrenProps) => {
  useEffect(() => {
    if (!orderDetailsFetched) {
      fetchOrderDetails();
    }
  }, [orderDetailsFetched, fetchOrderDetails]);

  useEffect(() => {
    if (pharmacy?.id) {
      checkPharmacyStatus(pharmacy.live, history, false, false);
      fetchServices(pharmacy?.id!);
    }
  }, [pharmacy]);

  const allergyTestingEnabled = getConfigVar('featureAllergyTesting').toLowerCase() === 'true';
  const covidVaccinationEnabled = getConfigVar('featureCovidVaccination').toLowerCase() === 'true' && pharmacy?.ownershipType === 'Owned';
  const onlineDoctorEnabled = getConfigVar('featureOnlineDoctor').toLowerCase() === 'true';

  const showIM1Reminder = !patient?.patientIntegration
    && !patient?.iM1Available
    && patient?.id?.length > 0
    && !patient?.iM1ReminderClosed;

  return (
    <Layout loading={loading || orderDetailsLoading} greenBackground>
      <MultiPatientSwitcher />
      <Space size={20} />
      {showIOSBanner && (
        <>
          <TextCenteredRoundedBox>
            <a href="https://apps.apple.com/gb/app/managemymeds/id1504750252">
              <img src={DownloadAppStore} alt="Download the Manage My Meds iOS App" />
            </a>
          </TextCenteredRoundedBox>
          <Space size={20} />
        </>
      )}
      {isIM1Enabled() && showIM1Reminder && <IM1Reminder closeButton />}
      <CardsContainer aria-label="Home cards">
        {allowManualReordering && <AnnouncementCard />}
        <ScheduleCard />
        {/* Orders card is displayed if the user has any live orders or if they are IM1 registered */}
        {(orders.length > 0 || (isIM1Enabled() && isIM1Registered)) && <OrdersCard />}
        {serviceBookingEnabled && <BookPharmacyServiceCard />}
        {isIM1Registered && messagesEnabled && <MessagesCard />}
        {allergyTestingEnabled && <AllergyTestingCard />}
        {onlineDoctorEnabled && <OnlineDoctorCard />}
        {covidVaccinationEnabled && (
          <PromotionCard
            onClick={() => {
              setSelectedService(COVID19_VAC_AND_BOOSTER_SERVICE);
              setSelectedSubService(null);
              history.push(
                Routes.PHARMACY_SERVICES,
              );
            }}
            showPromotion
            promoteServiceId={COVID19_VAC_AND_BOOSTER_SERVICE.id}
            promoteServiceCardText={COVID19_VAC_AND_BOOSTER_SERVICE.description}
            serviceNamesLookup={serviceNamesLookup}
            services={services}
          />
        )}
        <PromotedService
          pharmacy={pharmacy}
          onClick={(service: Service) => {
            setSelectedService(service);
            setSelectedSubService(null);
            history.push(
              Routes.PHARMACY_SERVICES,
            );
          }}
          serviceNamesLookup={serviceNamesLookup}
          services={services}
        />
        <AviClinicCard pharmacy={pharmacy} />
      </CardsContainer>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  loading: state.user.userDetailsLoading,
  isIM1Registered: !!state.user.patient.patientIntegration,
  allowManualReordering: state.user.patient.allowManualReordering,
  serviceBookingEnabled: selectPharmacyServiceBookingEnabled(state),
  messagesEnabled: selectMessagesEnabled(state),
  orders: state.orders.currentOrders?.filter(isLiveOrder),
  orderDetailsLoading: state.orders.orderDetailsLoading,
  orderDetailsFetched: state.orders.orderDetailsFetched,
  showIOSBanner: state.register.isIOS,
  pharmacy: state.user.pharmacy,
  patient: state.user.patient,
  serviceNamesLookup: state.pharmacyServices.serviceNamesLookup,
  services: state.pharmacyServices.services,
});

export default connect(mapState, {
  fetchOrderDetails: orderActions.fetchOrderDetails,
  setSelectedService: pharmacyServicesActions.setSelectedService,
  setSelectedSubService: pharmacyServicesActions.setSelectedSubService,
  fetchServices: pharmacyServicesActions.fetchServices,
  checkPharmacyStatus: userActions.checkPharmacyStatus,
})(Home);
